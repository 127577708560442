import React from 'react'
import { useRouter } from 'next/router'
import cx from 'classnames'
import styles from './Button.module.scss'
import { ButtonProps } from '~types'
import { formatAnalyticsText, fireAnalytics, getPageType } from '~analyticsUtils'
import { ANALYTICS_EVENT_BUTTON_CLICK, CLICK_CONTENT } from '~analyticsConstants'
import { gaDLPush } from 'utils/hooks/gaDLPush'
import { constructURL } from 'utils'

const Button = ({
  asLink,
  bgColor, // @depricated
  color,
  disabled,
  dataID,
  displayLinkInline,
  fill,
  isEnabled = true,
  isFullWidth,
  isTargetBlank,
  justify,
  linkId,
  id,
  linkModule,
  linkLocation,
  linkPosition,
  linkType,
  clickType,
  clickSource,
  clickTracking,
  cmsIdentifier,
  tagManagerEvent,
  onClick,
  size,
  title,
  url,
  wantCfc = true,
  className = ''
}: ButtonProps) => {
  const classNames = cx([
    styles['btn'],
    styles[`btn--${size}`],
    styles[`btn--justify-${justify}`],
    styles[`btn--${color}`],
    styles[`btn--fill-${fill}`],
    styles[disabled || (!url && !onClick) ? `btn--is-disabled` : ''],
    styles[isFullWidth ? 'btn--is-full-width' : ''],
    styles[className]
  ])

  const { asPath, query } = useRouter()
  const requestCameFrom = query.camefrom as string
  const requestCameFromUrl = requestCameFrom && wantCfc ? constructURL(url!, { key: 'camefrom', value: requestCameFrom }) : url

  const analyticsLinkData = (title: string, linkModule: string, linkLocation: string, linkId: string) => ({
    linkId: linkId ? linkId : formatAnalyticsText(title),
    linkModule: linkModule,
    linkLocation: linkLocation
  })

  const clickHandler = (e: React.MouseEvent<HTMLButtonElement>, title: string, linkModule: string, linkLocation: string, linkId: string) => {
    gaDLPush({
      event: tagManagerEvent || CLICK_CONTENT,
      click_source: clickSource,
      click_tracking: clickTracking,
      click_type: clickType,
      cms_identifier: cmsIdentifier,
      link_location: linkLocation,
      link_module: linkModule,
      link_position: linkPosition,
      link_type: linkType
    })
    fireAnalytics(ANALYTICS_EVENT_BUTTON_CLICK, getPageType(asPath), analyticsLinkData(title, linkModule, linkLocation, linkId))
    if (onClick) onClick(e)
    if (!asLink) {
      if (url) {
        if (isTargetBlank) {
          const ga = window[window['GoogleAnalyticsObject']]
          let gaLinkerDecoratedUrl

          if (ga && typeof ga.getAll === 'function') {
            const tracker = ga.getAll()[0]
            gaLinkerDecoratedUrl = new window.gaplugins.Linker(tracker).decorate(requestCameFromUrl)
          }
          window.open(gaLinkerDecoratedUrl, '_blank')
        } else {
          window.location.assign(requestCameFromUrl)
        }
      }
    }
  }

  let anchorProps = {
    className: classNames
  }
  const combinedClassNamesArray = []

  if (size === 'lg') {
    combinedClassNamesArray.push(styles['btn-lg-wrapper'], styles[`btn-lg-wrapper--justify-contents-${justify}`])
  } else if (asLink && displayLinkInline) {
    combinedClassNamesArray.push(styles['btn-display-inline-block'])
  }

  const combinedClassNames = combinedClassNamesArray.length > 0 ? cx(combinedClassNamesArray) : ''

  if (isTargetBlank) {
    anchorProps = {
      target: '_blank',
      rel: 'noreferrer',
      ...anchorProps
    }
  }

  return (
    <div className={combinedClassNames}>
      {asLink ? (
        <a
          {...(id && { id })}
          {...anchorProps}
          {...(!disabled
            ? {
                href: requestCameFromUrl,
                onClick: () => {
                  clickHandler(React.MouseEvent<HTMLButtonElement>, title, linkModule, linkLocation, linkId)
                }
              }
            : {
                url: 'javascript:void(0)'
              })}
        >
          {title}
        </a>
      ) : (
        <button
          {...(id && { id })}
          {...(dataID && { 'data-id': dataID })}
          className={classNames}
          disabled={disabled}
          {...(!disabled && {
            onClick: () => {
              clickHandler(React.MouseEvent<HTMLButtonElement>, title, linkModule, linkLocation, linkId)
            }
          })}
        >
          {title}
        </button>
      )}
    </div>
  )
}

Button.defaultProps = {
  color: 'light',
  bgColor: 'primary', // @depricated
  disabled: false,
  fill: 'solid',
  isFullWidth: false,
  isTargetBlank: false,
  justify: 'left',
  size: 'md'
}

export default Button
