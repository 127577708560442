import moment from 'moment'
import cx from 'classnames'

import { DATE_FORMAT, PST_TZ } from '~constants'
import Link from 'next/link'
import Image from 'next/image'
import { Heading } from '~elements'
import { TileViewEventDayProps } from 'types/calendar'
import { getValidShows } from 'utils/calendar'
import { useWindowSize } from 'utils/hooks'

import styles from '../../../ShowsCalendar.module.scss'

const TileViewEventDay = ({ date, events, handleDateSelection, artistDetails, artists }: TileViewEventDayProps) => {
  const eventTimestamp = moment.utc(date)
  const formattedDate = eventTimestamp.format(DATE_FORMAT.SHORT_DATE)
  const formattedDay = eventTimestamp.format(DATE_FORMAT.SHORT_DAY)
  const eventDate = eventTimestamp.format(DATE_FORMAT.SHORT_DATE_FORMAT)
  const MAX_SHOW_TIMES = 1
  const currentPSTDay = moment.utc().endOf('day').tz(PST_TZ).toDate()
  const isPastDate = eventTimestamp.isSameOrBefore(currentPSTDay, 'day')
  const isDateInvalid = eventTimestamp.isBefore(currentPSTDay, 'day')

  const artistsList = [...new Set(artists?.map(artist => artist?.id))]
  const sortedArtistByEvents = [...new Set(events?.flatMap(({ artists = [] }) => artists?.map(({ id }) => id)))]
  const filteredArtists = sortedArtistByEvents?.filter(eventArtist => artistsList?.some(artist => artist === eventArtist))
  const artistDetailsArray = (filteredArtists ?? []).map(artist => {
    const artistId = artist ?? null
    const artistImageObject = artistId && artistDetails ? artistDetails[artistId] : null

    return {
      artistId: artistId,
      artistName: artistImageObject ? artistImageObject?.artistName : null,
      artistEDPUrl: artistImageObject ? artistImageObject?.edpUrl : null,
      artistImageUrl: artistImageObject ? artistImageObject?.artistImage?.url : null
    }
  })

  const dayHasEvents = events.length > 0
  const dateClasses = cx([
    styles['calendar__date-dd'],
    !dayHasEvents && styles['calendar__day--has-no-events'],
    eventTimestamp.isSame(new Date(), 'day') && styles['calendar__day--active'],
    dayHasEvents && styles['calendar__day--has-events'],
    dayHasEvents && !eventTimestamp.isSame(new Date(), 'day') && styles['calendar__day--upcoming-event'],
    isPastDate && !dayHasEvents && styles['calendar__day--has-passed']
  ])

  const artistEvents = (artistId: any) => events.filter(event => event.artists.some(artist => artist.id === artistId))

  const eventTimes = (artistId: string) => {
    const filteredEvents = artistEvents(artistId)

    return getValidShows(filteredEvents)
  }
  const displayActiveTimes = activeTimes => {
    const slicedTimes = activeTimes.slice(0, MAX_SHOW_TIMES)
    return slicedTimes.map((event, index) => {
      const { id, start_time, is_sold_out } = event
      const formattedStartTime = moment(start_time, DATE_FORMAT.TWELVE_HRS).format(DATE_FORMAT.TWELVE_HRS)

      const btnClasses = cx([styles['calendar__event-time'], is_sold_out && styles['calendar__event--is-disabled']])

      return (
        <span key={id} disabled={is_sold_out} className={btnClasses} title={is_sold_out ? 'Tickets sold out!' : undefined}>
          {formattedStartTime}
          {index < slicedTimes.length - 1 && <>&#44; &nbsp;</>}
        </span>
      )
    })
  }

  const activeEvents = getValidShows(events)

  return (
    <>
      {activeEvents.length > 0 && (
        <div className={cx([styles['calendar__grid-day'], `${eventDate}`])}>
          <div className={styles['calendar__day-header']}>
            <div className={dateClasses}>
              <span className={styles['grid-day']}>{formattedDay}</span>
              <span className={styles['grid-date']}>{formattedDate}</span>
            </div>
          </div>
          <div className={styles['calendar__day-wrapper']}>
            {artistDetailsArray.map((a, index) => {
              const eventTimesForArtist = eventTimes(a.artistId)
              if (eventTimesForArtist.length > 0) {
                return (
                  <div key={index} className={styles['event-container']} onClick={() => handleDateSelection(eventTimesForArtist)}>
                    <Image className={styles['edp-image']} src={a.artistImageUrl} alt={a.artistName} priority width={0} height={0} sizes="100vw" />
                    <span className={styles['event-title']}>{a.artistName}</span>
                    <div className={styles['calendar__events']}>
                      {displayActiveTimes(eventTimesForArtist)}
                      {eventTimesForArtist.length > 1 && (
                        <span className={cx(styles['more-events'])}>&nbsp; + {eventTimesForArtist.length - MAX_SHOW_TIMES} more</span>
                      )}
                    </div>
                  </div>
                )
              } else {
                return null
              }
            })}
          </div>
        </div>
      )}
      {!isDateInvalid && activeEvents.length === 0 && (
        <div className={cx([styles['calendar__grid-day'], `${eventDate}`])}>
          <div className={styles['calendar__day-header']}>
            <div className={dateClasses}>
              <span className={styles['grid-day']}>{formattedDay}</span>
              <span className={styles['grid-date']}>{formattedDate}</span>
            </div>
          </div>
          <div className={styles['calendar__day-wrapper']}>
            <div className={cx(dayHasEvents && [styles['calendar__events'], !dayHasEvents && styles['calendar-height-100']])}>
              {activeEvents.length === 0 && (
                <div className={cx([styles['calendar__no-events'], [styles['calendar__no-events_with_margin']]])}>
                  <span>No show today</span>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default TileViewEventDay
