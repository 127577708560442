import { useRef, useState, lazy, Suspense, useEffect, useMemo } from 'react'
import styles from './TileCarousel.module.scss'
import cx from 'classnames'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons'
import { Heading, PlayButton, PauseButton } from '~elements'
import { useWindowSize, useIsSmartphoneLandscape } from 'utils/hooks'
import DetailsCard from '../DetailsCard/DetailsCard'
import { gaDLPush } from 'utils/hooks/gaDLPush'
// @ts-ignore
import Slider from 'react-slick'
import Image from 'next/image'

import { isMobileOnly } from 'react-device-detect'
const BackgroundVideo = lazy(() => import('components/elements/CarouselVideo/CarouselVideo'))

interface IUrl {
  url: string
}

interface TileCarouselProps {
  items: {
    image: {
      url: string
    }
    text: string
    cmsIdentifier: string
  }[]
  onTileClick?: (index: number) => void
  loopCarousel: boolean
  detailCards: any
  carouselBackground: { gif: IUrl[]; image: IUrl[]; videos: { posterImage: IUrl[]; video: IUrl[] }[] }[]
  businessUnit?: string
}

const TileCarousel = ({ items, loopCarousel = true, detailCards, carouselBackground, businessUnit }: TileCarouselProps) => {
  const sliderRef = useRef(null)
  const [detailsCardHeight, setDetailsCardHeight] = useState<number>(0)
  const [activeTileIndex, setActiveTileIndex] = useState<number>(0)
  const [isVideoPaused, setIsVideoPaused] = useState<boolean>(false)
  // const [progress, setProgress] = useState<number>(0)
  const [lastUpdateTime, setLastUpdateTime] = useState<number>(Date.now())
  const [disableTransition, setDisableTransition] = useState<boolean>(false)
  const { isBreakpoint: isBelowBreakpointMd } = useWindowSize('md', '<')
  const isSmartphoneLandscape = useIsSmartphoneLandscape() || isMobileOnly
  const autoplaySpeed = 7000
  const [playButtonActive, setPlayButtonActive] = useState(false)
  const [activeBackgroundImage, setActiveBackgroundImage] = useState()
  const [activeBackgroundGif, setActiveBackgroundGif] = useState()
  const [mediaDetails, setMediaDetails] = useState([])

  const next = () => {
    if (sliderRef.current) {
      sliderRef.current.slickNext()
    }
  }

  const previous = () => {
    if (sliderRef.current) {
      sliderRef.current.slickPrev()
    }
  }

  const slidePlay = () => {
    if (sliderRef.current) {
      sliderRef.current.slickPlay()
    }
  }

  const slidePause = () => {
    if (sliderRef.current) {
      sliderRef.current.slickPause()
    }
  }

  const handleManualClick = (ctaTitle: string, linkPosition: number, cmsIdentifier: string) => {
    gaDLPush({
      event: 'thumbnail_click',
      click_source: '/',
      click_tracking: `hero:thumbnail-cta:${ctaTitle.replaceAll(' ', '-')}`,
      click_type: 'cta',
      link_location: 'body',
      link_module: `hp_hero`,
      link_position: linkPosition,
      link_type: 'internal',
      cms_identifier: cmsIdentifier
    })
  }

  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     if (!isVideoPaused) {
  //       const now = Date.now()
  //       const elapsed = now - lastUpdateTime
  //       setLastUpdateTime(now)

  //       setProgress(prevProgress => {
  //         const newProgress = prevProgress + (elapsed / autoplaySpeed) * 100
  //         if (newProgress >= 100) {
  //           next()
  //           return 0
  //         }
  //         return newProgress
  //       })
  //     }
  //   }, 100)

  //   return () => clearInterval(interval)
  // }, [autoplaySpeed, isVideoPaused, lastUpdateTime])

  const carouselSettings = {
    customPaging: function (i: number) {
      const item = items[i]

      const handleMouseEnter = (i: number) => {
        if (!playButtonActive) {
          setIsVideoPaused(true)
        }
      }
      const handleMouseLeave = (i: number) => {
        if (!playButtonActive) {
          setIsVideoPaused(false)
        }
      }

      return (
        <div
          key={i}
          className={cx(styles['tile'], { [styles['tile-active']]: activeTileIndex === i })}
          onMouseEnter={() => handleMouseEnter(i)}
          onMouseLeave={() => handleMouseLeave(i)}
        >
          <div onClick={() => handleManualClick(item.text, i, item.cmsIdentifier)}>
            <Image src={item.image.url} alt={item.text} className={styles['tile-image']} width={300} height={175} />
            <div className={styles['tile-text']}>
              <Heading level={4}>{item.text}</Heading>
            </div>
          </div>
        </div>
      )
    },
    arrows: false,
    dots: true,
    dotsClass: styles.tiles,
    autoplay: true,
    infinite: loopCarousel,
    speed: 500,
    autoplaySpeed: autoplaySpeed,
    slidesToShow: 1,
    slidesToScroll: 1,
    beforeChange: (current: number, next: number) => {
      setDisableTransition(true)
      setActiveTileIndex(next)
      setTimeout(() => setDisableTransition(false), 5)
    },
    appendDots: (
      dots:
        | string
        | number
        | boolean
        | React.ReactElement<any, string | React.JSXElementConstructor<any>>
        | React.ReactFragment
        | React.ReactPortal
        | null
        | undefined
    ) => (
      <div>
        <ul data-type-landscape={isSmartphoneLandscape} className={cx('slick-dots', { [styles.tiles]: !isBelowBreakpointMd })}>
          {' '}
          {dots}{' '}
        </ul>
      </div>
    )
  }

  const getCarouselSettings = () => {
    if (isBelowBreakpointMd || isSmartphoneLandscape) {
      const { customPaging, ...rest } = carouselSettings

      return rest
    }

    return carouselSettings
  }

  useEffect(() => {
    if (isVideoPaused) {
      slidePause()
    } else {
      setLastUpdateTime(Date.now())
      // setProgress(prevProgress => prevProgress)
      slidePlay()
    }
  }, [isVideoPaused])

  const togglePlay = () => {
    setIsVideoPaused(false)
    setPlayButtonActive(false)
  }

  const togglePause = () => {
    setIsVideoPaused(true)
    setPlayButtonActive(true)
  }

  useEffect(() => {
    if (carouselBackground) {
      const details = carouselBackground.map(({ gif, videos, image }) => ({
        activeBackgroundVideo: videos.length > 0 && videos[0].video && videos[0].video.length > 0 && videos[0].video[0],
        activeBackgroundPosterImage: videos.length > 0 && videos[0].posterImage && videos[0].posterImage.length > 0 && videos[0].posterImage[0],
        activeBackgroundImage: image.length > 0 && image[0].url,
        activeBackgroundGif: gif.length > 0 && gif[0].url
      }))
      setMediaDetails(details)
      if (details[activeTileIndex]) {
        setActiveBackgroundImage(details[activeTileIndex].activeBackgroundImage)
        setActiveBackgroundGif(details[activeTileIndex].activeBackgroundGif)
      }
    }
  }, [activeTileIndex, carouselBackground])

  return (
    <div
      className={styles['home-page-container']}
      style={{
        backgroundImage: activeBackgroundImage ? `url(${activeBackgroundImage})` : activeBackgroundGif ? `url(${activeBackgroundGif})` : 'none'
      }}
    >
      <Slider {...getCarouselSettings()} ref={sliderRef}>
        {mediaDetails?.map(({ activeBackgroundPosterImage, activeBackgroundVideo }, index) => {
          return (
            <div key={index}>
              <div
                style={{
                  opacity: '60%'
                }}
              >
                {activeBackgroundVideo && activeBackgroundPosterImage && (
                  <Suspense fallback={<>Loading...</>}>
                    <BackgroundVideo
                      autoPlay={!isVideoPaused}
                      videoLoop={true}
                      posterImage={typeof activeBackgroundPosterImage === 'object' ? activeBackgroundPosterImage : { url: '' }}
                      backgroundVideo={activeBackgroundVideo}
                      isPaused={activeTileIndex !== index ? null : isVideoPaused}
                      detailsCardHeight={detailsCardHeight}
                    />
                  </Suspense>
                )}
              </div>
            </div>
          )
        })}
      </Slider>
      <DetailsCard
        description={detailCards[activeTileIndex].description}
        headline={detailCards[activeTileIndex].headline}
        eyebrow={detailCards[activeTileIndex].eyebrow}
        cta={detailCards[activeTileIndex].cta}
        isDescriptionVisibleDesktopOrTablet={detailCards[activeTileIndex].isDescriptionVisibleDesktopOrTablet}
        isDescriptionVisibleMobile={detailCards[activeTileIndex].isDescriptionVisibleMobile}
        eyebrowColor={detailCards[activeTileIndex].eyebrowColor}
        setDetailsCardHeight={setDetailsCardHeight}
        tileIndex={activeTileIndex}
        businessUnit={businessUnit}
      />
      <div className={styles['slider-nav-play-pause-container']}>
        <div>
          {activeBackgroundGif || activeBackgroundImage || isBelowBreakpointMd || isSmartphoneLandscape ? null : (
            <div className={cx([styles['play-pause-container']])}>
              {playButtonActive ? (
                <div className={cx([styles['play-pause']])} onClick={togglePlay}>
                  <PlayButton />
                </div>
              ) : (
                <div className={cx([styles['play-pause']])} onClick={togglePause}>
                  <PauseButton />
                </div>
              )}
            </div>
          )}
        </div>
        <div>
          {!isBelowBreakpointMd && !isSmartphoneLandscape && (
            <div className={styles['slider-nav-container']}>
              <div className={cx([styles['slick-arrow-custom']])} onClick={previous}>
                <FontAwesomeIcon icon={faChevronLeft} size="lg" />
              </div>
              <div className={cx([styles['slick-arrow-custom']])} onClick={next}>
                <FontAwesomeIcon icon={faChevronRight} size="lg" />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default TileCarousel
