import { Heading, Button } from '~elements'
import styles from './ShowsCard.module.scss'
import { ButtonProps } from '~types'
import { gaBuyTicketsClick } from 'utils/hooks/gaBtnClick'
import { useRouter } from 'next/router'
import parse from 'html-react-parser'
import { constructURL } from 'utils'

interface ShowsCardProps {
  backgroundImage: { url: string }
  imageRedirectUrl?: string
  buttonGroup?: Array<ButtonProps>
  headline?: string
  eyebrow?: string
  subheadline?: string
  description?: { html: string }
  businessUnit?: string
}

const ShowsCard = ({ backgroundImage, buttonGroup, headline, eyebrow, description, imageRedirectUrl, businessUnit }: ShowsCardProps) => {
  const router = useRouter()
  const [source] = router.query.slug as string[]
  const requestCameFrom = router.query.camefrom as string

  const handleCardClick = (e: any) => {
    if (!e.target.closest(`.${styles['btn-container']}`) && imageRedirectUrl) {
      router.push(requestCameFrom ? constructURL(imageRedirectUrl, { key: 'camefrom', value: requestCameFrom }) : imageRedirectUrl)
    }
  }

  const handleButtonClick = (title: string, buyTicket: string, cmsIdentifier: string, buttonPlacementDescription: string) => {
    if (title.toUpperCase() === 'BUY TICKETS') {
      const snakeCaseHeadline = headline
        ? headline
            .split(' ')
            .map(x => x.toLocaleLowerCase())
            .join('_')
        : ''

      gaBuyTicketsClick({
        businessUnit,
        buyType: buyTicket,
        clickSource: source ?? '',
        clickTracking: `${window.location.href} ${snakeCaseHeadline}:buy_tickets`,
        eventDate: '',
        eventVenueName: 'sphere_vegas',
        eventCategory: 'other',
        eventType: 'other events',
        eventDataStatus: '',
        linkLocation: ShowsCard.displayName,
        linkPosition: '',
        ticketmasterEventId: '',
        cmsIdentifier: cmsIdentifier,
        buttonPlacementDescription
      })
    }
  }

  return (
    <>
      <div className={styles['shows-card']} onClick={handleCardClick} style={{ cursor: imageRedirectUrl ? 'pointer' : 'default' }}>
        <div className={styles['image-container']}>
          {backgroundImage && (
            <div className={styles['image-wrapper']}>
              <img alt={headline} src={backgroundImage.url} />
            </div>
          )}
        </div>
        <div className={styles['shows-card-content']}>
          <div className={styles['shows-card-content__inner']}>
            {headline && <Heading level={2}>{headline}</Heading>}
            {eyebrow && (
              <Heading level={6} levelDisplay={'eyebrow'} color={'blue'}>
                {eyebrow}
              </Heading>
            )}
            {description && <p>{parse(description.html)} </p>}
            {buttonGroup && buttonGroup.length > 0 && (
              <div className={styles['btn-container']}>
                {buttonGroup.map((buttonProps, index) => {
                  if (buttonProps?.isEnabled) {
                    return (
                      <Button
                        key={`${buttonProps.title}_${index}`}
                        {...buttonProps}
                        onClick={() =>
                          handleButtonClick(
                            buttonProps.title,
                            buttonProps.title ?? '',
                            buttonProps.cmsIdentifier ?? '',
                            buttonProps.buttonPlacementDescription ?? ''
                          )
                        }
                      />
                    )
                  }
                })}
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  )
}

ShowsCard.displayName = 'shows_card'

export default ShowsCard
