import React, { useEffect, useRef, useState } from 'react'
import cx from 'classnames'
import styles from './DetailsCard.module.scss'
import { Col, Container, Row } from 'react-grid-system'
import { Button, Heading, Paragraph } from '~elements'
import { ButtonProps, WidgetSectionProps } from '~types'
import { useWindowSize, useIsSmartphoneLandscape } from 'utils/hooks'
import { ANALYTICS_LINK_MODULE_HERO, ANALYTICS_LINK_LOCATION_HEADER, ANALYTICS_LINK_MODULE_CTA } from '~analyticsConstants'
import { gaBuyTicketsClick } from 'utils/hooks/gaBtnClick'
import { isMobileOnly } from 'react-device-detect'

interface DetailsCardProps {
  buttonGroup?: Array<ButtonProps>
  ctaSection?: Array<ctaSectionTitle | WidgetSectionProps>
  description: {
    html: string
  }
  headline: string
  eyebrow: string
  cta?: ButtonProps
  eyebrowColor: string
  isDescriptionVisibleMobile: boolean
  isDescriptionVisibleDesktopOrTablet: boolean
  setDetailsCardHeight: React.Dispatch<React.SetStateAction<number>>
  businessUnit?: string
  tileIndex: number
}

interface ctaSectionTitle {
  title: string
}

type DetailsCardTextContentType = Pick<DetailsCardProps, 'description' | 'headline' | 'eyebrow'>

const DetailsCard = ({
  description,
  headline,
  eyebrow,
  cta,
  isDescriptionVisibleDesktopOrTablet,
  isDescriptionVisibleMobile,
  eyebrowColor,
  setDetailsCardHeight,
  businessUnit,
  tileIndex
}: DetailsCardProps) => {
  const observedElementRef = useRef<HTMLDivElement>(null)

  const [mountedUnmounted, setMountedUnmounted] = React.useState('')
  const [cardTextContent, setCardTextContent] = React.useState<DetailsCardTextContentType>({ description, headline, eyebrow })
  const { isBreakpoint: isBelowBreakpointSm } = useWindowSize('sm', '<')
  const { isBreakpoint: isBelowBreakpointMd } = useWindowSize('md', '<')
  const { isBreakpoint: isBelowBreakpointLg } = useWindowSize('lg', '<')
  const isSmartphoneLandscape = useIsSmartphoneLandscape() || isMobileOnly
  const paragraphFontSize = isSmartphoneLandscape || isSmartphoneLandscape ? 'md' : 'lg'

  useEffect(() => {
    if (observedElementRef.current) {
      const observer = new ResizeObserver(entries => {
        try {
          let bannerHeight = 0
          const detailsCardElement = entries[0]
          const detailsCardHeight = detailsCardElement.contentRect.height

          if (entries.length > 1) {
            const bannerElement = entries[1]
            const bannerDimension = bannerElement.target.getBoundingClientRect()
            bannerHeight = bannerDimension.height
          } else {
            const element = document.getElementById('notification-banner')

            if (element) {
              const bannerDimensionsRect = element.getBoundingClientRect()
              bannerHeight = bannerDimensionsRect.height
            }
          }

          const totalHeight = Math.round(detailsCardHeight) + Math.round(bannerHeight)

          setDetailsCardHeight(totalHeight)
        } catch (error) {
          console.log('Unable to fetch dimensions - ', error)
        }
      })

      observer.observe(observedElementRef.current)
      const bannerElement = document.getElementById('notification-banner')

      if (bannerElement) {
        observer.observe(bannerElement)
      }

      // Cleanup function
      return () => {
        observer.disconnect()
      }
    }
  }, [])

  React.useEffect(() => {
    let timeoutId: NodeJS.Timeout

    if (isBelowBreakpointMd) {
      timeoutId = setTimeout(() => {
        setMountedUnmounted('mounted')
        setCardTextContent({ description, headline, eyebrow })
      }, 250)

      setMountedUnmounted('unmounted')
    } else {
      setCardTextContent({ description, headline, eyebrow })
    }

    return () => clearTimeout(timeoutId)
  }, [description, headline, eyebrow])

  useEffect(() => {
    if (observedElementRef.current) {
      const observer = new ResizeObserver(entries => {
        try {
          let bannerHeight = 0
          const detailsCardElement = entries[0]
          const detailsCardHeight = detailsCardElement.contentRect.height

          if (entries.length > 1) {
            const bannerElement = entries[1]
            const bannerDimension = bannerElement.target.getBoundingClientRect()
            bannerHeight = bannerDimension.height
          } else {
            const element = document.getElementById('notification-banner')

            if (element) {
              const bannerDimensionsRect = element.getBoundingClientRect()
              bannerHeight = bannerDimensionsRect.height
            }
          }

          const totalHeight = Math.round(detailsCardHeight) + Math.round(bannerHeight)

          setDetailsCardHeight(totalHeight)
        } catch (error) {
          console.log('Unable to fetch dimensions - ', error)
        }
      })

      observer.observe(observedElementRef.current)
      const bannerElement = document.getElementById('notification-banner')

      if (bannerElement) {
        observer.observe(bannerElement)
      }

      // Cleanup function
      return () => {
        observer.disconnect()
      }
    }
  }, [])

  React.useEffect(() => {
    let timeoutId: NodeJS.Timeout

    if (isBelowBreakpointMd) {
      timeoutId = setTimeout(() => {
        setMountedUnmounted('mounted')
        setCardTextContent({ description, headline, eyebrow })
      }, 250)

      setMountedUnmounted('unmounted')
    } else {
      setCardTextContent({ description, headline, eyebrow })
    }

    return () => clearTimeout(timeoutId)
  }, [description, headline, eyebrow])

  const getIsDescriptionVisible = () => {
    if (!description) return false

    if (isBelowBreakpointMd) {
      return isDescriptionVisibleMobile
    } else {
      return isDescriptionVisibleDesktopOrTablet
    }
  }

  const handleCTAClick = (buttonProps: ButtonProps) => {
    if (buttonProps.title.toLocaleLowerCase() === 'buy tickets') {
      const snakeCaseHeadline = headline
        .split(' ')
        .map(x => x.toLocaleLowerCase())
        .join('_')

      gaBuyTicketsClick({
        businessUnit,
        buyType: buttonProps.buyType,
        clickSource: '/',
        clickTracking: `${window.location.href} ${snakeCaseHeadline}:buy_tickets`,
        eventDate: '',
        eventVenueName: 'sphere_vegas',
        eventCategory: 'other',
        eventType: 'other events',
        eventDataStatus: '',
        linkLocation: DetailsCard.displayName,
        linkPosition: tileIndex,
        ticketmasterEventId: '',
        cmsIdentifier: buttonProps.cmsIdentifier,
        buttonPlacementDescription: buttonProps.buttonPlacementDescription
      })
    }
  }

  return (
    <Container className={cx(styles['container'], styles['parent-container'])} data-type-landscape={isSmartphoneLandscape}>
      <Row className={cx(styles[mountedUnmounted])}>
        <Col>
          <div className={cx([styles['card-details-container']])} ref={observedElementRef}>
            <div className={styles['card-details']}>
              <div className={styles['headline-container']}>
                <div className={styles['eyebrow']}>
                  {eyebrow && (
                    <Heading level={'eyebrow'} justify={isBelowBreakpointLg ? 'center' : 'left'} color={eyebrowColor}>
                      {cardTextContent.eyebrow}
                    </Heading>
                  )}
                </div>
                <div className={styles['headlinegap']}>
                  {headline && (
                    <Heading level={1} justify={isBelowBreakpointLg ? 'center' : 'left'}>
                      <span className={styles['headline']}>{cardTextContent.headline}</span>
                    </Heading>
                  )}
                </div>
                {getIsDescriptionVisible() && (
                  <Paragraph fontSize={paragraphFontSize} text={cardTextContent.description} justify={isBelowBreakpointLg ? 'center' : 'left'} />
                )}
                {
                  // @ts-ignore
                  cta && cta.length > 0 && (
                    <div className={styles['btn-container']} data-type-landscape={isSmartphoneLandscape}>
                      {
                        // @ts-ignore
                        cta.map((buttonProps, index) => (
                          <Button
                            key={`${buttonProps.title}_${index}`}
                            {...buttonProps}
                            linkModule={`${ANALYTICS_LINK_MODULE_HERO}:${ANALYTICS_LINK_LOCATION_HEADER}`}
                            linkLocation={ANALYTICS_LINK_LOCATION_HEADER}
                            clickType={`content:${ANALYTICS_LINK_MODULE_CTA}`}
                            linkType="internal"
                            onClick={() => handleCTAClick(buttonProps)}
                          />
                        ))
                      }
                    </div>
                  )
                }
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  )
}

DetailsCard.displayName = 'details_card'

export default DetailsCard
